import { Avatar, Box, Flex, Heading, Text } from '@chakra-ui/react'
import { Formik } from 'formik'
import { MeUser } from 'ordercloud-javascript-sdk'
import * as Yup from 'yup'
import useAuth from '../../context/AuthContext'
import SaveBar from '../Shared/SaveBar/SaveBar'
import AccountDetails from './AccountDetails/AccountDetails'
import ChangePassword from './ChangePassword/ChangePassword'
import PersonalDetails from './PersonalDetails/PersonalDetails'

const MyAccount: React.FC = () => {
  const { user, updateUser } = useAuth()

  const handleSubmit = (userUpdates: MeUser) => {
    if (user) {
      let newUser = Object.assign(user, userUpdates)
      updateUser(newUser)
    }
  }

  return (
    <>
      <Heading backgroundColor="#FAFAFA" p="6">
        <Flex alignItems={'center'}>
          <Avatar size={'lg'} name={`${user?.FirstName} ${user?.LastName}`} />
          <Text mx="6">
            {user?.FirstName} {user?.LastName}
          </Text>
        </Flex>
      </Heading>

      <Formik
        enableReinitialize
        initialValues={{
          Username: user?.Username ?? '',
          Email: user?.Email ?? '',
          FirstName: user?.FirstName ?? '',
          LastName: user?.LastName ?? '',
          Phone: user?.Phone ?? '',
        }}
        validationSchema={Yup.object({
          Username: Yup.string().required(),
          Email: Yup.string().required().email(),
          FirstName: Yup.string().required(),
          LastName: Yup.string().required(),
          Phone: Yup.string().matches(
            /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
            'Phone number is invalid'
          ),
        })}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, errors, touched, isValid }) => (
          <form onSubmit={handleSubmit} id="account-details">
            <Flex>
              <Flex flexDirection="column" flex="1">
                <Box
                  flex="1"
                  backgroundColor={'#FAFAFA'}
                  m="5"
                  p="5"
                  style={{
                    borderRadius: 15,
                  }}
                >
                  <Heading as="h2" size="md" my="3">
                    Account Details
                  </Heading>

                  <AccountDetails />
                </Box>
                <Box
                  backgroundColor={'#FAFAFA'}
                  m="5"
                  p="5"
                  style={{
                    borderRadius: 15,
                  }}
                >
                  <Heading as="h2" size="md" my="3">
                    Security
                  </Heading>
                  <ChangePassword />
                </Box>
              </Flex>
              <Box
                flex="1"
                backgroundColor={'#FAFAFA'}
                m="5"
                p="5"
                style={{
                  borderRadius: 15,
                }}
              >
                <Heading as="h2" size="md" my="3">
                  Personal Details
                </Heading>
                <PersonalDetails />
              </Box>
            </Flex>
            <SaveBar />
          </form>
        )}
      </Formik>
    </>
  )
}

export default MyAccount
