import { Button, Stack, Td, Tr } from '@chakra-ui/react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { useOcCostCenters, useOcSpendingAccounts } from '../../../common/hooks'
import FilterMenu from '../../Shared/FilterMenu/FilterMenu'
import OcTable from '../../Shared/OcTable/OcTable'
import SearchInput from '../../Shared/SearchInput/SearchInput'
export enum AccountType {
  SpendingAccounts = 'spending-accounts',
  CostCenters = 'cost-centers',
}
export const BuyerPaymentMethodsList = () => {
  const { buyerID, method } = useParams()
  const {
    loading: spendingAccountsLoading,
    items: spendingAccountItems,
    listOptions: spendingAccountListOptions,
    onQueryParamChange: onSpendingAccountQueryParamChange,
    hasQueryParams: hasSpendingAccountQueryParams,
    resetQueryParams: resetSpendingAccountQueryParams,
  } = useOcSpendingAccounts(buyerID!)
  const {
    loading: costCentersLoading,
    items: costCentersItems,
    listOptions: costCentersListOptions,
    onQueryParamChange: onCostCentersQueryParamChange,
    hasQueryParams: hasCostCentersQueryParams,
    resetQueryParams: resetCostCentersQueryParams,
  } = useOcCostCenters(buyerID!)

  // const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  return (
    <>
      <Stack
        direction="row"
        spacing={4}
        marginBottom={6}
        justify="space-between"
      >
        <Stack direction="row" spacing={4}>
          {method === AccountType.SpendingAccounts && (
            <SearchInput
              value={spendingAccountListOptions.search}
              onChange={onSpendingAccountQueryParamChange('search')}
              debounce={800}
            />
          )}
          {method === AccountType.CostCenters && (
            <SearchInput
              value={costCentersListOptions.search}
              onChange={onCostCentersQueryParamChange('search')}
              debounce={800}
            />
          )}
          <FilterMenu
            label={undefined}
            value={method?.split('-').join(' ')}
            onChange={(v: any) => {
              navigate(`/buyers/${buyerID}/payment-methods/${v}`)
            }}
            options={[
              {
                label: 'Spending accounts',
                value: 'spending-accounts',
              },
              {
                label: 'Cost centers',
                value: 'cost-centers',
              },
            ]}
            includeClearOption={false}
          />
        </Stack>
        <Button variant="solid" colorScheme="brand">
          New {method?.split('-').join(' ')}
        </Button>
      </Stack>
      {method === AccountType.SpendingAccounts && (
        <OcTable
          variant="simple"
          headers={['Name', 'ID', 'Balance']}
          colGroups={['50%', '25%', '25%']}
          loading={spendingAccountsLoading}
          emptyList={!spendingAccountItems?.length}
          hasQueryParams={hasSpendingAccountQueryParams}
          resetQueryParams={resetSpendingAccountQueryParams}
          resourceName="spending accounts"
        >
          {spendingAccountItems?.map((sa, i) => {
            return (
              <Tr
                key={i}
                as={NavLink}
                display="table-row"
                to={`/buyers/${buyerID}/payment-methods/spending-accounts/${sa.ID}`}
                state={sa}
              >
                <Td>
                  <b>{sa.Name}</b>
                </Td>
                <Td>{sa.ID}</Td>
                <Td>${sa.Balance}</Td>
              </Tr>
            )
          })}
        </OcTable>
      )}
      {method === AccountType.CostCenters && (
        <OcTable
          variant="simple"
          headers={['Name', 'ID', 'Description', 'Assigned to']}
          colGroups={['16.66%', '16.66%', '50%', '16.66%']}
          loading={costCentersLoading}
          emptyList={!costCentersItems?.length}
          hasQueryParams={hasCostCentersQueryParams}
          resetQueryParams={resetCostCentersQueryParams}
          resourceName="cost centers"
        >
          {costCentersItems?.map((cc, i) => {
            return (
              <Tr
                key={i}
                as={NavLink}
                display="table-row"
                to={`/buyers/${buyerID}/payment-methods/cost-centers/${cc.ID}`}
                state={cc}
              >
                <Td>
                  <b>{cc.Name}</b>
                </Td>
                <Td>{cc.ID}</Td>
                <Td>{cc.Description}</Td>
                <Td>dev incomplete</Td>
              </Tr>
            )
          })}
        </OcTable>
      )}
    </>
  )
}
