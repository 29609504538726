import { Address, Addresses } from 'ordercloud-javascript-sdk'
import { useMemo } from 'react'
import useOcListPage from './useOcListPage'

export const useOcBuyerAddresses = <XpType = any>(buyerID: string) => {
  const routeParams = useMemo(() => {
    return new Array(buyerID!)
  }, [buyerID])

  return useOcListPage<Address<XpType>>(Addresses.List, routeParams)
}

export default useOcBuyerAddresses
