import {
  Badge,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  Stack,
  Td,
  Tr,
} from '@chakra-ui/react'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isNil } from 'lodash'
import { ChangeEvent, useCallback, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import useOcAllBuyerUsers from '../../../common/hooks/useOcAllBuyerUsers'
import FilterMenu from '../../Shared/FilterMenu/FilterMenu'
import OcTable from '../../Shared/OcTable/OcTable'
import SearchInput from '../../Shared/SearchInput/SearchInput'

export const AllBuyerUsersList = () => {
  const {
    loading,
    items,
    onQueryParamChange,
    resetQueryParams,
    hasQueryParams,
    listOptions,
  } = useOcAllBuyerUsers()

  const statusFilterValue = useMemo(() => {
    if (isNil(listOptions.filters?.Active)) {
      return
    }
    return listOptions.filters?.Active ? 'active' : 'inactive'
  }, [listOptions.filters])

  const handleStatusFilterChange = useCallback(
    (value: string | string[] | null) => {
      onQueryParamChange('Active')({
        target: { value: isNil(value) ? null : value === 'active' },
      } as ChangeEvent<any>)
    },
    [onQueryParamChange]
  )

  return (
    <Box padding={10} paddingTop={4}>
      <Breadcrumb
        marginBottom={8}
        spacing={4}
        separator={
          <FontAwesomeIcon size="xs" color="gray" icon={faChevronRight} />
        }
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={NavLink} to="/">
            Home
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink as={NavLink} to="/buyers">
            Buyers
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink as={NavLink} to="/buyer-users">
            All buyer users
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex justifyContent="space-between">
        <Heading marginBottom="6">All buyer users</Heading>
      </Flex>
      <Stack
        direction="row"
        spacing={4}
        marginBottom={6}
        justify="space-between"
      >
        <Stack direction="row" spacing={4}>
          <SearchInput
            value={listOptions.search}
            onChange={onQueryParamChange('search')}
            debounce={800}
          />
          <FilterMenu
            label="Status"
            value={statusFilterValue}
            onChange={handleStatusFilterChange}
            options={[
              {
                label: 'Active',
                value: 'active',
              },
              {
                label: 'Inactive',
                value: 'inactive',
              },
            ]}
          />
        </Stack>
        <Button variant="solid" colorScheme="brand">
          New user
        </Button>
      </Stack>
      <OcTable
        variant="simple"
        headers={['Name', 'Email', 'Username', 'ID', 'Status']}
        loading={loading}
        emptyList={!items?.length}
        hasQueryParams={hasQueryParams}
        resetQueryParams={resetQueryParams}
        resourceName="users"
      >
        {items?.map((u, i) => {
          return (
            <Tr
              key={i}
              as={NavLink}
              display="table-row"
              to={`/buyers/${u.CompanyID}/users/${u.ID}`}
            >
              <Td>
                <b>{u.FirstName ? `${u.FirstName} ${u.LastName}` : ''}</b>
              </Td>
              <Td>{u.Email}</Td>
              <Td>{u.Username}</Td>
              <Td>{u.ID}</Td>
              <Td>
                <Badge colorScheme={u.Active ? 'green' : 'yellow'}>
                  {u.Active ? `ACTIVE` : `INACTIVE`}
                </Badge>
              </Td>
            </Tr>
          )
        })}
      </OcTable>
    </Box>
  )
}
