import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Heading,
  Skeleton,
  Text,
  useToast,
} from '@chakra-ui/react'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { OrderCloudError, User, Users } from 'ordercloud-javascript-sdk'
import { useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import SaveBar from '../../Shared/SaveBar/SaveBar'
import Toast from '../../Shared/Toast/Toast'
import UserForm from '../../Shared/UserForm/UserForm'

export const BuyerUserDetails = () => {
  const { buyerID, userID } = useParams()
  const [user, setUser] = useState<User>()
  const [error, setError] = useState<OrderCloudError>()
  const toast = useToast()
  error && console.log(error)
  useEffect(() => {
    Users.Get(buyerID!, userID!)
      .then((user) => setUser(user))
      .catch((err: OrderCloudError) => setError(err))
  }, [buyerID, userID])

  const saveUser = (editedUser: User) => {
    Users.Save(buyerID!, userID!, editedUser)
      .then(() => {
        toast({
          render: () => (
            <Toast>
              <Text>User was updated successfully</Text>
            </Toast>
          ),
        })
      })
      .catch((err: OrderCloudError) => setError(err))
  }

  return (
    <Box padding={10} paddingTop={4}>
      <Breadcrumb
        marginBottom={8}
        spacing={4}
        separator={
          <FontAwesomeIcon size="xs" color="gray" icon={faChevronRight} />
        }
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={NavLink} to="/">
            Home
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink as={NavLink} to="/buyers">
            Buyers
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink as={NavLink} to={`/buyers/${buyerID}`}>
            Sierra
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink as={NavLink} to={`/buyers/${buyerID}/users`}>
            Users
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <Skeleton isLoaded={!!user}>
            <BreadcrumbLink
              as={NavLink}
              to={`/buyers/${buyerID}/users/${userID}`}
            >
              {`${user?.FirstName} ${user?.LastName}`}
            </BreadcrumbLink>
          </Skeleton>
        </BreadcrumbItem>
      </Breadcrumb>
      <Skeleton isLoaded={!!user}>
        <Heading as="h2" marginBottom={6}>
          {`${user?.FirstName} ${user?.LastName}`}
        </Heading>
      </Skeleton>
      <UserForm
        user={user}
        saveCallback={saveUser}
        renderSaveComponent={<SaveBar formid="user" />}
      />
    </Box>
  )
}
