import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react'
import { Field, connect, useFormikContext } from 'formik'

interface FormInputProps {
  name: string
  id: string
  readonly?: boolean
  type: string
}

const FormInput: React.FC<FormInputProps> = ({ name, id, readonly, type }) => {
  const { errors, touched }: any = useFormikContext()
  const error = errors[id]
  const touch = touched[id]
  return (
    <FormControl my="3" isInvalid={!!error && touch} id={id}>
      <FormLabel>{name}</FormLabel>
      <Field
        as={Input}
        backgroundColor={'white'}
        id={id}
        name={id}
        readOnly={readonly}
        type={type}
      />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  )
}

Input.defaultProps = {
  readonly: false,
}

export default connect(FormInput)
