import { FormControl, FormLabel, Switch } from '@chakra-ui/react'
import { Formik } from 'formik'
import { Buyer } from 'ordercloud-javascript-sdk'
import { PropsWithChildren } from 'react'
import FormInput from '../Shared/FormInput/FormInput'
import * as yup from 'yup'

interface BuyerFormProps {
  buyer?: Buyer
  renderSaveComponent: any
  saveCallback: (buyer: Buyer) => void
}
const BuyerCreateForm: React.FC<PropsWithChildren<BuyerFormProps>> = ({
  buyer,
  saveCallback,
  renderSaveComponent,
}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        Name: buyer?.Name ?? '',
        ID: buyer?.ID ?? '',
        Active: buyer?.Active ?? true,
      }}
      validationSchema={yup.object({
        Name: yup.string().required(),
        ID: yup.string(),
        Active: yup.boolean().required(),
      })}
      onSubmit={saveCallback}
    >
      {({ handleSubmit, handleChange, resetForm, values }: any) => (
        <>
          <form id="buyer" onSubmit={handleSubmit}>
            <FormInput type="text" name="Name" id="Name" />
            <FormInput type="text" name="ID" id="ID" />
            <FormControl>
              <FormLabel>Active</FormLabel>
              <Switch
                id="Active"
                name="Active"
                isChecked={values.Active}
                onChange={handleChange}
              />
            </FormControl>
          </form>
          {renderSaveComponent}
        </>
      )}
    </Formik>
  )
}

export default BuyerCreateForm
