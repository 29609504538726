import { Box, Heading, useToast, Text } from '@chakra-ui/react'
import {
  Address,
  AddressAssignment,
  Addresses,
} from 'ordercloud-javascript-sdk'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useOc } from '../../../common/hooks'
import useOcListPage from '../../../common/hooks/useOcListPage'
import AddressForm from '../../Shared/AddressForm/AddressForm'
import SaveBar from '../../Shared/SaveBar/SaveBar'
import Toast from '../../Shared/Toast/Toast'

export const BuyerAddressDetails = () => {
  const { buyerID, addressID } = useParams()
  const addressRouteParams = useMemo(() => {
    return [buyerID!, addressID!]
  }, [buyerID, addressID])
  const assignmentsRouteParams = useMemo(() => {
    return [buyerID!]
  }, [buyerID])
  const { loading: assignmentsLoading, items: assignmentsItems } =
    useOcListPage<AddressAssignment>(
      Addresses.ListAssignments,
      assignmentsRouteParams
    )
  const { loading: addressLoading, data: addressData } = useOc<Address>(
    Addresses.Get,
    addressRouteParams
  )
  const toast = useToast()

  const saveAddress = (editedAddress: Address) => {
    Addresses.Save(buyerID!, addressID!, editedAddress).then(() => {
      toast({
        render: () => (
          <Toast>
            <Text>Address was updated successfully</Text>
          </Toast>
        ),
      })
    })
  }

  return (
    <>
      {addressLoading && <p>...Loading</p>}
      {!addressLoading && (
        <>
          <Heading>{addressData?.AddressName}</Heading>
          <AddressForm
            address={addressData}
            renderSaveComponent={<SaveBar formid="address" />}
            saveCallback={saveAddress}
          />
        </>
      )}
      <Box style={{ padding: 5 }}>
        {assignmentsLoading && <p>...assignments loading</p>}
        {!assignmentsLoading && (
          <>
            <p>Assigned to</p>
            {assignmentsItems &&
              assignmentsItems?.map((a, idx) => {
                return <pre key={idx}>{JSON.stringify(a, null, 4)}</pre>
              })}
          </>
        )}
      </Box>
    </>
  )
}
