import { useCallback, useEffect, useMemo, useState } from 'react'

export const useOc = <T>(
  getCallback: (...args: any[]) => Promise<T>,
  params: string[],
  state?: T
) => {
  const [data, setData] = useState<T>()
  const [loading, setLoading] = useState(true)
  const fetchData = useCallback(async () => {
    setLoading(true)
    let response: T
    if (state) {
      response = state
    } else {
      response = await getCallback(...params)
    }
    setData(response)
    setLoading(false)
  }, [params, getCallback, state])

  const refresh = useCallback(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const result = useMemo(() => {
    return {
      loading,
      data,
      refresh,
    }
  }, [loading, data, refresh])

  return result
}

export default useOc
