import {
  ChakraProvider,
  extendTheme,
  // theme as defaultTheme,
} from '@chakra-ui/react'
import { AuthProvider } from './context/AuthContext'
import MainContent from './layout/MainContent/MainContent'
import Routing from './layout/Routing/Routing'
import TopNavigation from './layout/TopNavigation/TopNavigation'
import colors from './theme/foundations/colors'
import sitecoreTheme from './theme/theme'

//Add brand primary via theme extension
const theme = extendTheme({ colors: { brand: colors.primary } }, sitecoreTheme)

function App() {
  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <TopNavigation />
        <MainContent>
          <Routing />
        </MainContent>
      </AuthProvider>
    </ChakraProvider>
  )
}

export default App
