import {
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/react'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isArray, isNil } from 'lodash'
import { FC, useCallback, useMemo } from 'react'

export interface FilterMenuOption {
  value: string
  label: string
}

export interface FilterMenuProps {
  type?: 'radio' | 'checkbox'
  value?: string | string[]
  onChange: any
  label?: string
  options: FilterMenuOption[]
  closeOnSelect?: boolean
  includeClearOption?: boolean
}

export const FilterMenu: FC<FilterMenuProps> = ({
  type = 'radio',
  closeOnSelect = true,
  value,
  onChange,
  label,
  options,
  includeClearOption = true,
}) => {
  const isNilValue = useMemo(() => {
    return isNil(value)
  }, [value])

  const handleFilterChange = useCallback(
    (value: string | string[]) => {
      onChange(value)
    },
    [onChange]
  )

  const handleClearFilters = useCallback(() => {
    onChange(null)
  }, [onChange])

  const filterMenuLabel = useMemo(() => {
    if (isNil(label)) {
      return value
    }
    if (isNil(value)) {
      return `${label}: All`
    }
    if (isArray(value)) {
      const selectedOpts = options.filter((o) => value.includes(o.value))
      return `${label}: ${selectedOpts.map((o) => `${o.label}, `)}`
    }
    const selectedOpt = options.find((o) => o.value === value)
    return `${label}: ${selectedOpt?.label || 'Unknown'}`
  }, [label, options, value])

  return (
    <Menu closeOnSelect={closeOnSelect}>
      <MenuButton
        data-testid="filter-menu"
        variant="outline"
        colorScheme={isNilValue ? 'gray' : 'brand'}
        as={Button}
        rightIcon={<FontAwesomeIcon icon={faAngleDown} />}
      >
        {filterMenuLabel}
      </MenuButton>
      <MenuList>
        <MenuOptionGroup
          type={type}
          value={value || (type === 'checkbox' ? [] : '')}
          onChange={handleFilterChange}
        >
          {options.map((o, i) => (
            <MenuItemOption key={i} value={o.value} data-testid={o.value}>
              {o.label}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
        {includeClearOption && (
          <>
            <MenuDivider />
            <MenuItem
              data-testid="clear-filters"
              color="gray.400"
              fontWeight="semibold"
              onClick={handleClearFilters}
            >
              Clear
            </MenuItem>
          </>
        )}
      </MenuList>
    </Menu>
  )
}

export default FilterMenu
