import {
  Box,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import {
  Address,
  AddressAssignment,
  Addresses,
  Buyer,
  Buyers,
  ListPage,
  OrderCloudError,
} from 'ordercloud-javascript-sdk'
import { useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import ConfirmDeleteModal from '../Shared/ConfirmDelete/ConfirmDeleteModal'
import SaveBar from '../Shared/SaveBar/SaveBar'
import Toast from '../Shared/Toast/Toast'
import BuyerCreateForm from './BuyerForm'

const BuyerDetails = () => {
  const { buyerID } = useParams()
  const [buyer, setBuyer] = useState<Buyer>()
  const [addressAssignmentList, setAddressAssignmentList] =
    useState<ListPage<AddressAssignment>>()
  const [addresses, setAddresses] = useState<Address[]>()
  const [error, setError] = useState<OrderCloudError>()
  const navigate = useNavigate()
  const toast = useToast()

  error && console.log(error)
  useEffect(() => {
    Buyers.Get(buyerID!)
      .then((buyer) => {
        setBuyer(buyer)
      })
      .catch((err: OrderCloudError) => setError(err))
    Addresses.ListAssignments(buyerID!, { level: `Company`, pageSize: 10 })
      .then((listPage) => setAddressAssignmentList(listPage))
      .catch((err: OrderCloudError) => setError(err))
  }, [buyerID])

  useEffect(() => {
    let addressReqs = addressAssignmentList?.Items?.map(
      (a: AddressAssignment) => Addresses.Get(buyerID!, a?.AddressID!)
    )
    addressReqs && Promise.all(addressReqs).then((res) => setAddresses(res))
  }, [addressAssignmentList, buyerID])

  const handleDeleteBuyer = async () => {
    await Buyers.Delete(buyerID!)
    navigate(`/buyers`)
  }

  const saveBuyer = (editedBuyer: Buyer) => {
    Buyers.Save(buyerID!, editedBuyer)
      .then((res) => {
        toast({
          render: () => (
            <Toast>
              <Text>{editedBuyer.Name} was updated successfully</Text>
            </Toast>
          ),
        })
      })
      .catch((err: OrderCloudError) => setError(err))
  }

  return (
    <Box>
      <Box
        style={{
          marginTop: 20,
          borderRadius: 15,
          padding: '20px',
          backgroundColor: '#FAFAFA',
        }}
      >
        <p style={{ fontSize: 18, fontWeight: 'bold' }}>Buyer details</p>
        <BuyerCreateForm
          buyer={buyer}
          saveCallback={saveBuyer}
          renderSaveComponent={<SaveBar formid="buyer" />}
        />
      </Box>
      <Box
        style={{
          marginTop: 20,
          borderRadius: 15,
          padding: '20px',
          backgroundColor: '#FAFAFA',
        }}
      >
        <p style={{ fontSize: 18, fontWeight: 'bold' }}>Addresses</p>
        <p style={{ fontSize: 11, color: 'rgba(0, 0, 0, 0.55)' }}>
          Assigned to this buyer
        </p>
        <TableContainer style={{ marginBottom: 25, marginTop: 25 }}>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Address name</Th>
                <Th>ID</Th>
                <Th>Name</Th>
                <Th>Address</Th>
              </Tr>
            </Thead>
            <Tbody>
              {addresses?.map((a, i) => {
                return (
                  <Tr key={i}>
                    <Td>
                      <NavLink to={`/buyers/${buyerID}/addresses/${a.ID}`}>
                        {a.AddressName ?? '-'}
                      </NavLink>
                    </Td>
                    <Td>{a.ID}</Td>
                    <Td>{a.FirstName ? `${a.FirstName} ${a.LastName}` : ''}</Td>
                    <Td>{`${a.Street1} ${a.Street2 ? `, ${a.Street2}` : ``}, ${
                      a.City
                    }, ${a.State}, ${a.Zip}, ${a.Country}`}</Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      <ConfirmDeleteModal
        resourceType="buyer"
        resourceName={buyer?.Name!}
        onConfirm={handleDeleteBuyer}
      >
        <Button variant="outline" colorScheme="gray" marginTop="48px">
          Delete buyer
        </Button>
      </ConfirmDeleteModal>
    </Box>
  )
}

export default BuyerDetails
