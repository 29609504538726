import { CostCenter, CostCenters } from 'ordercloud-javascript-sdk'
import { useMemo } from 'react'
import useOcListPage from './useOcListPage'

export const useOcCostCenters = <XpType = any>(buyerID: string) => {
  const routeParams = useMemo(() => {
    return new Array(buyerID!)
  }, [buyerID])

  return useOcListPage<CostCenter<XpType>>(CostCenters.List, routeParams)
}

export default useOcCostCenters
