import FormInput from '../../Shared/FormInput/FormInput'

const PersonalDetails: React.FC = () => {
  return (
    <fieldset>
      <FormInput type="text" name="First Name" id="FirstName" />
      <FormInput type="text" name="Last Name" id="LastName" />
      <FormInput type="text" name="Phone" id="Phone" />
    </fieldset>
  )
}

export default PersonalDetails
