import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react'
import { Field, Formik } from 'formik'
import { SpendingAccount } from 'ordercloud-javascript-sdk'
import { PropsWithChildren } from 'react'
import FormInput from '../FormInput/FormInput'
import * as yup from 'yup'

interface SpendingAccountFormProps {
  spendingAccount?: SpendingAccount
  renderSaveComponent: any
  saveCallback: (spendingAccount: SpendingAccount) => void
}
const SpendingAccountForm: React.FC<
  PropsWithChildren<SpendingAccountFormProps>
> = ({ spendingAccount, saveCallback, renderSaveComponent }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        Name: spendingAccount?.Name ?? '',
        ID: spendingAccount?.ID ?? '',
        Balance: spendingAccount?.Balance ?? 0,
        RedemptionCode: spendingAccount?.RedemptionCode ?? '',
        StartDate: spendingAccount?.StartDate ?? '',
        EndDate: spendingAccount?.EndDate ?? '',
      }}
      validationSchema={yup.object({
        Name: yup.string().required(),
        ID: yup.string(),
        Balance: yup.number().required(),
        RedemptionCode: yup.string(),
        StartDate: yup.date(),
        EndDate: yup.date(),
      })}
      onSubmit={saveCallback}
    >
      {({ handleSubmit, errors, touched }: any) => (
        <>
          <form id="spending-account" onSubmit={handleSubmit}>
            <FormInput type="text" name="Name" id="Name" />
            <FormInput type="text" name="ID" id="ID" />
            <FormInput type="text" name="Redemption Code" id="RedemptionCode" />
            <FormInput type="number" name="Balance" id="Balance" />
            {/* use react-datepicker component? */}
            <FormControl
              my="3"
              isInvalid={!!errors.StartDate && touched.StartDate}
              id="StartDate"
            >
              <FormLabel>Start Date</FormLabel>
              <Field
                as={Input}
                backgroundColor={'white'}
                id="StartDate"
                name="StartDate"
                type="datetime-local"
              />
              <FormErrorMessage>{errors.StartDate}</FormErrorMessage>
            </FormControl>
            <FormControl
              my="3"
              isInvalid={!!errors.EndDate && touched.EndDate}
              id="EndDate"
            >
              <FormLabel>End Date</FormLabel>
              <Field
                as={Input}
                backgroundColor={'white'}
                id="EndDate"
                name="EndDate"
                type="datetime-local"
              />
              <FormErrorMessage>{errors.EndDate}</FormErrorMessage>
            </FormControl>
          </form>
          {renderSaveComponent}
        </>
      )}
    </Formik>
  )
}

export default SpendingAccountForm
