import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react'
import React, { PropsWithChildren } from 'react'

interface CreateDrawerProps {
  isOpen: boolean
  onOpen: any
  onClose: any
  resourceType: string
}
const CreateDrawer: React.FC<PropsWithChildren<CreateDrawerProps>> = ({
  isOpen,
  onOpen,
  onClose,
  resourceType,
  children,
}) => {
  return (
    <>
      <Button
        rounded={'full'}
        alignSelf="center"
        marginBottom="6"
        onClick={onOpen}
      >
        New {resourceType}
      </Button>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Create new {resourceType}</DrawerHeader>
          <DrawerBody>{children}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default CreateDrawer
