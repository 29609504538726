import { Box, Skeleton, Text, useToast } from '@chakra-ui/react'
import { CostCenter, CostCenters } from 'ordercloud-javascript-sdk'
import { useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useOc } from '../../../common/hooks'
import CostCenterForm from '../../Shared/CostCenterForm/CostCenterForm'
import SaveBar from '../../Shared/SaveBar/SaveBar'
import Toast from '../../Shared/Toast/Toast'

export const BuyerCostCenterDetails = () => {
  const { buyerID, costCenterID } = useParams()
  const { state } = useLocation()
  const routeParams = useMemo(() => {
    return [buyerID!, costCenterID!]
  }, [buyerID, costCenterID])
  const { loading, data } = useOc<CostCenter>(
    CostCenters.Get,
    routeParams,
    state as CostCenter
  )
  const toast = useToast()

  const saveCostCenter = (editedCostCenter: CostCenter) => {
    CostCenters.Save(buyerID!, costCenterID!, editedCostCenter).then(() => {
      toast({
        render: () => (
          <Toast>
            <Text>Spending account was updated successfully</Text>
          </Toast>
        ),
      })
    })
  }

  return (
    <Skeleton isLoaded={!loading}>
      <Text fontSize={30} fontWeight="bold">
        {data?.Name}
      </Text>
      <Box
        style={{
          margin: 20,
          borderRadius: 15,
          padding: '20px',
          backgroundColor: '#FAFAFA',
        }}
      >
        <p style={{ fontSize: 18, fontWeight: 'bold' }}>cost center details</p>
        <CostCenterForm
          renderSaveComponent={<SaveBar formid="cost-center" />}
          costCenter={data}
          saveCallback={saveCostCenter}
        />
      </Box>
    </Skeleton>
  )
}
