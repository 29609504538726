import { Box, Button, Heading } from '@chakra-ui/react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC, useCallback } from 'react'

interface NoResultsProps {
  icon?: IconProp
  title: string
  onClear?: () => void
}

const NoResults: FC<NoResultsProps> = ({
  title,
  onClear,
  icon = faMagnifyingGlass,
}) => {
  const handleClearFilters = useCallback(() => {
    if (typeof onClear !== 'function') {
      return
    }
    onClear()
  }, [onClear])

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="md"
    >
      <Box
        width={20}
        bg="chakra-border-color"
        borderRadius="lg"
        fontSize="x-large"
        color="GrayText"
        height={20}
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginBottom="6"
      >
        <FontAwesomeIcon icon={icon}></FontAwesomeIcon>
      </Box>
      <Heading as="p" size="md" marginBottom="1">
        {title}
      </Heading>
      {onClear && (
        <Button size="sm" variant="link" onClick={handleClearFilters}>
          Clear filters
        </Button>
      )}
    </Box>
  )
}

export default NoResults
