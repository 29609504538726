import { isNil } from 'lodash'
import { ListPage } from 'ordercloud-javascript-sdk'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { explodeListOptions } from '../utilities/explodeOptions'
import { parseSearchParams } from '../utilities/parseSearchParams'

export interface OcListPageResult<ResourceType> {
  loading: boolean
  listOptions: { [key: string]: any }
  hasQueryParams: boolean
  items?: ResourceType[]
  meta?: any
  refreshList: () => void
  resetQueryParams: () => void
  onQueryParamChange: (key: string) => (e: ChangeEvent<any>) => void
}

const useOcListPage = <T>(
  listCallback: any,
  routeParams?: string[]
): OcListPageResult<T> => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState(true)

  const listOptions = useMemo(() => {
    return parseSearchParams(searchParams, [
      'search',
      'searchOn',
      'page',
      'pageSize',
      'sortBy',
    ])
  }, [searchParams])

  const hasQueryParams = useMemo(() => {
    return Object.values(listOptions).length > 0
  }, [listOptions])

  const [data, setData] = useState<ListPage<T>>()

  const fetchData = useCallback(async () => {
    setLoading(true)
    let response
    if (routeParams) {
      response = await listCallback(...routeParams, listOptions)
    } else {
      response = await listCallback(listOptions)
    }
    setData(response)
    setLoading(false)
  }, [routeParams, listOptions, listCallback])

  const refreshList = useCallback(() => {
    fetchData()
  }, [fetchData])

  const resetQueryParams = useCallback(() => {
    const newParams = new URLSearchParams()
    setSearchParams(newParams)
  }, [setSearchParams])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const onQueryParamChange = useCallback(
    (key: string) => (e: ChangeEvent<any>) => {
      let urlSearchParams
      if (isNil(e.target.value) || e.target.value === '') {
        urlSearchParams = new URLSearchParams(explodeListOptions(listOptions))
        urlSearchParams.delete(key)
      } else {
        urlSearchParams = new URLSearchParams({
          ...explodeListOptions(listOptions),
          [key]: e.target.value,
        })
      }

      setSearchParams(urlSearchParams)
    },
    [listOptions, setSearchParams]
  )

  const result = useMemo(() => {
    return {
      loading,
      listOptions,
      hasQueryParams,
      items: data?.Items,
      meta: data?.Meta,
      refreshList,
      resetQueryParams,
      onQueryParamChange,
    }
  }, [
    loading,
    data,
    listOptions,
    hasQueryParams,
    refreshList,
    resetQueryParams,
    onQueryParamChange,
  ])

  return result
}

export default useOcListPage
