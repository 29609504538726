import { Heading } from '@chakra-ui/react'

const NoMatch: React.FC = () => {
  return (
    <>
      <Heading>Page not found.</Heading>
    </>
  )
}

export default NoMatch
