import { Box, Button, VStack, Flex } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'
import { NavLink } from 'react-router-dom'
import { NavItem } from '../../../layout/TopNavigation/TopNavigation'

interface LeftDrawerProps {
  items: NavItem[]
}
const LeftDrawer: React.FC<PropsWithChildren<LeftDrawerProps>> = ({
  children,
  items,
}) => {
  return (
    <Flex
      display="flex"
      flexDirection="row"
      flexGrow={1}
      flexShrink={1}
      height="calc(100vh - 57px)"
      overflow="hidden"
    >
      <>
        <Box
          order={1}
          bg="panel-background"
          overflow="auto"
          minW="240px"
          w="15vw"
        >
          <VStack mx="2" mt="4">
            {items.map((item, idx) => (
              <Button
                as={NavLink}
                to={item.route}
                size="sm"
                variant="ghost"
                textAlign={'left'}
                w="full"
                justifyContent="left"
                key={idx}
                _activeLink={{ bg: 'primary.100' }}
              >
                {item.label}
              </Button>
            ))}
          </VStack>
        </Box>
        <Box overflow="auto" order={2} w="full">
          {children}
        </Box>
      </>
    </Flex>
  )
}

export default LeftDrawer
