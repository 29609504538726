import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react'
import { faSearch, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { debounce as db } from 'lodash'
import { ChangeEvent, FC, useEffect, useState } from 'react'

interface SearchInputProps {
  value?: string
  debounce?: number
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const SearchInput: FC<SearchInputProps> = ({ value, debounce, onChange }) => {
  const [inputValue, setInputValue] = useState<string>(value || '')

  useEffect(() => {
    setInputValue(value || '')
  }, [value])

  const handleClearSearch = () => {
    onChange({ target: { value: '' } } as any)
  }

  return (
    <InputGroup width="sm">
      <InputLeftElement
        pointerEvents="none"
        color="gray.300"
        fontSize="1.2em"
        children={<FontAwesomeIcon icon={faSearch} />}
      />
      <Input
        id="search"
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value)
          debounce ? db(onChange, debounce)(e) : onChange(e)
        }}
        placeholder="Search"
      />
      {inputValue && (
        <InputRightElement>
          <IconButton
            size="xs"
            colorScheme="gray"
            aria-label="Clear search"
            onClick={handleClearSearch}
          >
            <FontAwesomeIcon icon={faXmark}></FontAwesomeIcon>
          </IconButton>
        </InputRightElement>
      )}
    </InputGroup>
  )
}

export default SearchInput
