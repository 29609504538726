import {
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Td,
  Tr,
} from '@chakra-ui/react'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { debounce } from 'lodash'
import { Link, useParams } from 'react-router-dom'
import { useOcApprovalRules } from '../../../common/hooks'
import OcTable from '../../Shared/OcTable/OcTable'

export const BuyerApprovalRulesList = () => {
  const { buyerID } = useParams()
  const {
    loading,
    items,
    onQueryParamChange,
    hasQueryParams,
    resetQueryParams,
    listOptions,
  } = useOcApprovalRules(buyerID!)

  return (
    <>
      <Stack spacing={4} width={320}>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            color="gray.300"
            fontSize="1.2em"
            children={<FontAwesomeIcon icon={faSearch} />}
          />
          <Input
            id="search"
            defaultValue={listOptions.search}
            onChange={debounce(onQueryParamChange('search'), 1500)}
            placeholder="Search"
          />
        </InputGroup>
      </Stack>
      <OcTable
        variant="simple"
        headers={['Rule name', 'ID', 'Description', 'Approving group']}
        loading={loading}
        emptyList={!items?.length!}
        hasQueryParams={hasQueryParams}
        resetQueryParams={resetQueryParams}
        resourceName="approval rules"
      >
        {items?.map((ar, i) => {
          return (
            <Tr key={i}>
              <Td>
                <Link to={`/buyers/${buyerID}/approval-rules/${ar.ID}`}>
                  {ar.Name}
                </Link>
              </Td>
              <Td>{ar.ID}</Td>
              <Td>{ar.Description}</Td>
              <Td>{ar.ApprovingGroupID}</Td>
            </Tr>
          )
        })}
      </OcTable>
    </>
  )
}
