import { Box, Heading, Skeleton, Text, useToast } from '@chakra-ui/react'
import {
  OrderCloudError,
  SpendingAccount,
  SpendingAccounts,
} from 'ordercloud-javascript-sdk'
import { useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useOc } from '../../../common/hooks'
import SaveBar from '../../Shared/SaveBar/SaveBar'
import SpendingAccountForm from '../../Shared/SpendingAccountForm/SpendingAccountForm'
import Toast from '../../Shared/Toast/Toast'

export const BuyerSpendingAccountDetails = () => {
  const { buyerID, spendingAccountID } = useParams()
  const { state } = useLocation()
  const toast = useToast()
  const routeParams = useMemo(() => {
    return [buyerID!, spendingAccountID!]
  }, [buyerID, spendingAccountID])
  const { loading, data } = useOc<SpendingAccount>(
    SpendingAccounts.Get,
    routeParams,
    state as SpendingAccount
  )

  const saveSpendingAccount = (editedAccount: SpendingAccount) => {
    SpendingAccounts.Save(buyerID!, spendingAccountID!, editedAccount)
      .then(() => {
        toast({
          render: () => (
            <Toast>
              <Text>Spending account was updated successfully</Text>
            </Toast>
          ),
        })
      })
      .catch((err: OrderCloudError) => console.log(err))
  }

  return (
    <Skeleton isLoaded={!loading}>
      <Heading>Payment Method Details</Heading>
      <Box
        style={{
          margin: 20,
          borderRadius: 15,
          padding: '20px',
          backgroundColor: '#FAFAFA',
        }}
      >
        <SpendingAccountForm
          spendingAccount={data}
          saveCallback={saveSpendingAccount}
          renderSaveComponent={<SaveBar formid="spending-account" />}
        />
      </Box>
    </Skeleton>
  )
}
