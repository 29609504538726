export const attemptParseValue = (value: string) => {
  try {
    return JSON.parse(value)
  } catch {
    const split = value.split(',')
    if (split.length > 1) {
      return split.join('|')
    } else {
      return value
    }
  }
}
