import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Heading,
  Skeleton,
  Tab,
  TabList,
  Tabs,
} from '@chakra-ui/react'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Buyer, Buyers, OrderCloudError } from 'ordercloud-javascript-sdk'
import { useEffect, useState } from 'react'
import { NavLink, Outlet, useParams } from 'react-router-dom'
import { NavItem } from '../../layout/TopNavigation/TopNavigation'

const BuyerContainer = () => {
  const { buyerID } = useParams()

  const NAV_ITEMS: Array<NavItem> = [
    {
      label: 'Details',
      route: `/buyers/${buyerID}`,
    },
    {
      label: 'Users',
      route: `/buyers/${buyerID}/users`,
    },
    {
      label: 'Groups',
      route: `/buyers/${buyerID}/groups`,
    },
    {
      label: 'Addresses',
      route: `/buyers/${buyerID}/addresses`,
    },
    {
      label: 'Payment methods',
      route: `/buyers/${buyerID}/payment-methods/spending-accounts`,
    },
    {
      label: 'Approval rules',
      route: `/buyers/${buyerID}/approval-rules`,
    },
    {
      label: 'Promotions',
      route: `/buyers/${buyerID}/promotions`,
    },
  ]
  const [buyer, setBuyer] = useState<Buyer>()
  const [error, setError] = useState<OrderCloudError>()
  error && console.log(error)
  useEffect(() => {
    Buyers.Get(buyerID!)
      .then((buyer) => {
        setBuyer(buyer)
      })
      .catch((err: OrderCloudError) => setError(err))
  }, [buyerID])

  return (
    <Box padding={10} paddingTop={4}>
      <Breadcrumb
        marginBottom={8}
        spacing={4}
        separator={
          <FontAwesomeIcon size="xs" color="gray" icon={faChevronRight} />
        }
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={NavLink} to="/">
            Home
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink as={NavLink} to="/buyers">
            Buyers
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <Skeleton isLoaded={!!buyer}>
            <BreadcrumbLink as={NavLink} to={`/buyers/${buyerID}`}>
              {`${buyer?.Name}`}
            </BreadcrumbLink>
          </Skeleton>
        </BreadcrumbItem>
      </Breadcrumb>
      <Skeleton isLoaded={!!buyer}>
        <Heading as="h2" marginBottom={6}>
          {buyer?.Name || 'loading'}
        </Heading>
      </Skeleton>
      <Tabs variant="soft-rounded" colorScheme="brand" marginBottom={6}>
        <TabList>
          {NAV_ITEMS.map(({ label, route }) => {
            return (
              //TODO: figure out how to use NavLink isActive state to control Tab.isSelected
              <Tab key={route} as={NavLink} to={route}>
                <p>{label}</p>
              </Tab>
            )
          })}
        </TabList>
      </Tabs>
      <Outlet />
    </Box>
  )
}

export default BuyerContainer
