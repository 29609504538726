import React from 'react'
import { Outlet } from 'react-router-dom'
import { NavItem } from '../../layout/TopNavigation/TopNavigation'
import LeftDrawer from '../Shared/LeftDrawerNav/LeftDrawerNav'

const Buyers: React.FC<any> = () => {
  const items: Array<NavItem> = [
    { label: 'Buyers', route: '/buyers' },
    { label: 'All Buyer Users', route: '/buyer-users' },
  ]

  return (
    <LeftDrawer items={items}>
      <Outlet />
    </LeftDrawer>
  )
}

export default Buyers
