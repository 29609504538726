import { theme } from '@chakra-ui/react'
import { StyleFunctionProps } from '@chakra-ui/theme-tools'

const Button = {
  baseStyle: {
    rounded: 'full',
  },
  defaultProps: {
    colorScheme: 'primary',
  },
  variants: {
    primary: {
      color: 'white',
      bg: 'primary.500',
      _hover: {
        bg: 'primary.600',
      },
      _active: {
        bg: 'primary.700',
      },
    },
    secondary: (props: StyleFunctionProps) => ({
      ...theme.components.Button.variants.outline(props),
      color: 'pink.500',
      borderColor: 'pink.900',
      _hover: {
        color: 'white',
        backgroundColor: 'pink.500',
        borderColor: 'pink.500',
      },
    }),
    // secondary: {
    //   border: "1px solid",
    //   borderColor: "chakra-border-color",
    //   color: "blackAlpha.600",
    //   _hover: {
    //     bg: "blackAlpha.100",
    //   },
    //   _active: {
    //     bg: "blackAlpha.200",
    //   },
    // },
    danger: {
      color: 'white',
      bg: 'red.500',
      _hover: {
        bg: 'red.600',
      },
      _active: {
        bg: 'red.700',
      },
    },

    subtle: {
      color: 'primary.600',
      _hover: {
        bg: 'blackAlpha.100',
      },
      _active: {
        bg: 'primary.100',
      },
    },
    minimal: {
      color: 'blackAlpha.600',
      _hover: {
        bg: 'blackAlpha.100',
      },
      _active: {
        bg: 'blackAlpha.200',
      },
    },
    navHorizontal: {
      px: '2',
      color: 'blackAlpha.600',
      rounded: 'md',
      _hover: {
        bg: 'blackAlpha.100',
      },
      _active: {
        bg: 'blackAlpha.200',
      },
    },
    navHorizontalActive: {
      size: 'sm',
      px: '2',
      color: 'primary.600',
      rounded: 'md',
      bg: 'primary.100',
      _active: {
        bg: 'primary.200',
      },
    },
    navVertical: {
      px: '3',
      justifyContent: 'none',
      color: 'blackAlpha.600',
      rounded: 'md',
      _hover: {
        bg: 'blackAlpha.100',
      },
      _active: {
        bg: 'blackAlpha.200',
      },
    },
    navVerticalActive: {
      px: '3',
      justifyContent: 'none',
      color: 'primary.600',
      rounded: 'md',
      bg: 'primary.100',
      _active: {
        bg: 'primary.200',
      },
    },
  },
  sizes: {
    lg: {
      fontSize: 'md',
    },
    sm: {
      fontSize: 'md',
    },
    xs: {
      fontSize: 'sm',
    },
  },
}

export default Button
