import { Box, Input, Text } from '@chakra-ui/react'
import { ApprovalRules, ApprovalRule } from 'ordercloud-javascript-sdk'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

export const BuyerApprovalRuleDetails = () => {
  const { buyerID, approvalRuleID } = useParams()
  const [approvalRule, setApprovalRule] = useState<ApprovalRule>()
  useEffect(() => {
    const getApprovalRule = async () => {
      const approvalRule = await ApprovalRules.Get(buyerID!, approvalRuleID!)
      setApprovalRule(approvalRule)
    }
    getApprovalRule()
  }, [approvalRuleID, buyerID])
  return (
    <Box
      style={{
        margin: 20,
        borderRadius: 15,
        padding: '20px',
        backgroundColor: '#FAFAFA',
      }}
    >
      {approvalRule &&
        Object.values(approvalRule!).map((v: any, idx: number) => {
          const keys = Object.keys(approvalRule!)
          return (
            <Box key={idx}>
              <Text mb="8px">{keys?.[idx]}</Text>
              <Input readOnly value={v ?? ''} />
            </Box>
          )
        })}
    </Box>
  )
}
