import { FormControl, FormLabel, Textarea } from '@chakra-ui/react'
import { Formik } from 'formik'
import { CostCenter } from 'ordercloud-javascript-sdk'
import { PropsWithChildren } from 'react'
import FormInput from '../FormInput/FormInput'
import * as yup from 'yup'

interface CostCenterFormProps {
  costCenter?: CostCenter
  renderSaveComponent: any
  saveCallback: (spendingAccount: CostCenter) => void
}
const CostCenterForm: React.FC<PropsWithChildren<CostCenterFormProps>> = ({
  costCenter,
  saveCallback,
  renderSaveComponent,
}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        Name: costCenter?.Name ?? '',
        ID: costCenter?.ID ?? '',
        Description: costCenter?.Description ?? '',
      }}
      validationSchema={yup.object({
        Name: yup.string().required(),
        ID: yup.string(),
        Description: yup.string(),
      })}
      onSubmit={saveCallback}
    >
      {({ handleSubmit, errors, touched, values }: any) => (
        <>
          <form id="cost-center" onSubmit={handleSubmit}>
            <FormInput type="text" name="Name" id="Name" />
            <FormInput type="text" name="ID" id="ID" />
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea
                id="Description"
                name="Description"
                value={values.Description}
              />
            </FormControl>
          </form>
          {renderSaveComponent}
        </>
      )}
    </Formik>
  )
}

export default CostCenterForm
