import { UserGroup, UserGroups } from 'ordercloud-javascript-sdk'
import { useMemo } from 'react'
import useOcListPage from './useOcListPage'

export const useOcBuyerGroups = <XpType = any>(buyerID: string) => {
  const routeParams = useMemo(() => {
    return new Array(buyerID!)
  }, [buyerID])

  return useOcListPage<UserGroup<XpType>>(UserGroups.List, routeParams)
}

export default useOcBuyerGroups
