import { Box, Heading } from '@chakra-ui/react'
import { Formik } from 'formik'
import { Address } from 'ordercloud-javascript-sdk'
import { PropsWithChildren } from 'react'
import * as yup from 'yup'
import FormInput from '../FormInput/FormInput'

interface AddressFormProps {
  address?: any
  renderSaveComponent: any
  saveCallback: (address: Address) => void
}
const AddressForm: React.FC<PropsWithChildren<AddressFormProps>> = ({
  address,
  saveCallback,
  renderSaveComponent,
}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        AddressName: address?.AddressName ?? '',
        ID: address?.ID ?? '',
        CompanyName: address?.CompanyName ?? '',
        Phone: address?.Phone ?? '',
        FirstName: address?.FirstName ?? '',
        LastName: address?.LastName ?? '',
        Country: address?.Country ?? '',
        Street1: address?.Street1 ?? '',
        Street2: address?.Street2 ?? '',
        City: address?.City ?? '',
        State: address?.State ?? '',
        Zip: address?.Zip ?? '',
      }}
      validationSchema={yup.object({
        AddressName: yup.string(),
        ID: yup.string(),
        CompanyName: yup.string(),
        Phone: yup
          .string()
          .matches(
            /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
            'Phone number is invalid'
          ),
        FirstName: yup.string(),
        LastName: yup.string(),
        Country: yup.string().required(),
        Street1: yup.string().required(),
        Street2: yup.string(),
        City: yup.string().required(),
        State: yup.string().required(),
        Zip: yup.string().required(), // which zip codes are valid? regex pattern?
      })}
      onSubmit={saveCallback}
    >
      {({ handleSubmit }: any) => (
        <>
          <form id="address" onSubmit={handleSubmit}>
            <Box
              flex="1"
              backgroundColor={'#FAFAFA'}
              m="5"
              p="5"
              style={{
                borderRadius: 15,
              }}
            >
              <Heading as="h2" size="md" my="3">
                Details
              </Heading>
              <FormInput type="text" name="Address Name" id="AddressName" />
              <FormInput type="text" name="ID" id="ID" />
            </Box>
            <Box
              backgroundColor={'#FAFAFA'}
              m="5"
              p="5"
              style={{
                borderRadius: 15,
              }}
            >
              <Heading as="h2" size="md" my="3">
                Contact
              </Heading>
              <FormInput type="text" name="Company Name" id="CompanyName" />
              <FormInput type="text" name="Phone" id="Phone" />
              <FormInput type="text" name="First Name" id="FirstName" />
              <FormInput type="text" name="Last Name" id="LastName" />
            </Box>

            <Box
              flex="1"
              backgroundColor={'#FAFAFA'}
              m="5"
              p="5"
              style={{
                borderRadius: 15,
              }}
            >
              <Heading as="h2" size="md" my="3">
                Address
              </Heading>
              <FormInput type="text" name="Street 1" id="Street1" />
              <FormInput type="text" name="Street 2" id="Street2" />
              <FormInput type="text" name="City" id="City" />
              <FormInput type="text" name="State" id="State" />
              <FormInput type="text" name="Country" id="Country" />
              <FormInput type="text" name="Zip Code" id="Zip" />
            </Box>
          </form>
          {renderSaveComponent}
        </>
      )}
    </Formik>
  )
}

export default AddressForm
