import {
  Flex,
  Spacer,
  Button,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  ModalBody,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  InputRightElement,
  InputGroup,
  IconButton,
  FormErrorMessage,
} from '@chakra-ui/react'
import { Field, Formik } from 'formik'
import { useState } from 'react'
import useAuth from '../../../context/AuthContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import * as Yup from 'yup'
import PasswordStrength from '../PasswordStrength/PasswordStrength'
import FormSubmitButton from '../../Shared/FormSubmitButton/FormSubmitButton'

const ChangePassword: React.FC = () => {
  const [show, setShow] = useState<boolean>(false)
  const { updatePassword } = useAuth()
  const { onOpen, isOpen, onClose } = useDisclosure()
  const handleSubmit = (values: any) => {
    updatePassword(values.Password)
    onClose() //TODO: await success
  }

  /* this component uses a custom validator and form controls 
because we need to see all password errors displayed at once */

  const fullValidatorForSchema = (schema: any) => (values: any) =>
    schema
      .validate(values, {
        abortEarly: false,
        strict: false,
      })
      .then(() => ({}))
      .catch(({ inner }: any) =>
        inner.reduce(
          (memo: any, { path, message }: any) => ({
            ...memo,
            [path]: (memo[path] || []).concat(message),
          }),
          {}
        )
      )

  const validationSchema = Yup.object({
    Password: Yup.string()
      .required('Password is required')
      .min(10, 'Password must be 10 or more characters')
      .matches(/[a-z]+/, 'Password must contain at least 1 lowercase character')
      .matches(/[A-Z]+/, 'Password must contain at least 1 uppercase character')
      .matches(/\d+/, 'Password must contain at least 1 number'),
    ConfirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf(
        [Yup.ref('Password'), null],
        'Confirm Password must match Password'
      ),
  })

  return (
    <>
      <Button
        rounded={'full'}
        colorScheme="gray"
        variant="outline"
        onClick={onOpen}
      >
        Change Password
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Password</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={{
                Password: '',
                ConfirmPassword: '',
              }}
              validate={fullValidatorForSchema(validationSchema)}
              onSubmit={handleSubmit}
            >
              {({ handleSubmit, resetForm, errors, touched, isValid }) => (
                <form onSubmit={handleSubmit} id="password-reset">
                  <FormControl
                    my="3"
                    isInvalid={!!errors.Password && touched.Password}
                  >
                    <FormLabel>Create New Password</FormLabel>
                    <InputGroup>
                      <Field
                        as={Input}
                        backgroundColor={'white'}
                        type={show ? 'text' : 'password'}
                        id="Password"
                        name="Password"
                      />
                      <InputRightElement width="4.5rem">
                        <IconButton
                          variant="ghost"
                          aria-label="show or hide password"
                          onClick={() => setShow(!show)}
                          icon={
                            show ? (
                              <FontAwesomeIcon size="sm" icon={faEyeSlash} />
                            ) : (
                              <FontAwesomeIcon size="sm" icon={faEye} />
                            )
                          }
                        ></IconButton>
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>
                      {errors.Password?.slice(0, 1)}
                    </FormErrorMessage>
                  </FormControl>

                  <PasswordStrength errors={errors.Password} />
                  <FormControl
                    my="3"
                    isInvalid={
                      !!errors.ConfirmPassword && touched.ConfirmPassword
                    }
                    id="ConfirmPassword"
                  >
                    <FormLabel>Confirm New Password</FormLabel>
                    <InputGroup>
                      <Field
                        as={Input}
                        backgroundColor={'white'}
                        type={show ? 'text' : 'password'}
                        id="ConfirmPassword"
                        name="ConfirmPassword"
                      />
                      <InputRightElement width="4.5rem">
                        <IconButton
                          variant="ghost"
                          aria-label="show or hide password"
                          onClick={() => setShow(!show)}
                          icon={
                            show ? (
                              <FontAwesomeIcon size="sm" icon={faEyeSlash} />
                            ) : (
                              <FontAwesomeIcon size="sm" icon={faEye} />
                            )
                          }
                        ></IconButton>
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>
                      {errors.ConfirmPassword?.slice(0, 1)}
                    </FormErrorMessage>
                  </FormControl>
                  <Flex>
                    <Spacer />
                    <Button
                      variant="outline"
                      m="2"
                      onClick={() => {
                        resetForm()
                        onClose()
                      }}
                    >
                      Cancel
                    </Button>
                    <FormSubmitButton m="2">Submit</FormSubmitButton>
                  </Flex>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ChangePassword
