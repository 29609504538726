import { attemptParseValue } from './attemptParseValue'

export const parseSearchParams = (sp: URLSearchParams, toInclude: string[]) => {
  const result: { [key: string]: any } = {}

  sp.forEach((value, key) => {
    if (toInclude.includes(key)) {
      result[key] = attemptParseValue(value)
      return
    }
    if (!result['filters']) {
      result['filters'] = {}
    }
    result['filters'][key] = attemptParseValue(value)
  })

  return result
}
