import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { PropsWithChildren } from 'react'

interface ConfirmDeleteProps {
  resourceType: string
  resourceName: string
  onConfirm: () => void
}

const ConfirmDeleteModal: React.FC<PropsWithChildren<ConfirmDeleteProps>> = ({
  resourceType,
  resourceName,
  onConfirm,
  children,
}) => {
  const { onOpen, isOpen, onClose } = useDisclosure()
  return (
    <>
      <div onClick={onOpen}>{children}</div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete {resourceType}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            You are about to delete {resourceName}. This action cannot be
            undone.
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              onClick={() => {
                onConfirm()
                onClose()
              }}
              rounded="full"
            >
              Delete
            </Button>
            <Button onClick={onClose} variant="link">
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ConfirmDeleteModal
