import { Text, useToast } from '@chakra-ui/react'
import { Auth, Me, MeUser, Tokens } from 'ordercloud-javascript-sdk'
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import Toast from '../components/Shared/Toast/Toast'
import appConstants from '../config/app.constants'

interface AuthContextType {
  // We defined the user type in `index.d.ts`, but it's
  // a simple object with email, name and password.
  isLoggedIn: boolean
  user?: MeUser
  loading: boolean
  login: (username: string, password: string) => Promise<void>
  logout: () => void
  updateUser: (user?: MeUser) => void
  updatePassword: (password: string) => void
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType)

// Export the provider as we need to wrap the entire app with it
export function AuthProvider({
  children,
}: {
  children: ReactNode
}): JSX.Element {
  const [user, setUser] = useState<MeUser>()
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [loading, setLoading] = useState<boolean>(true)
  const toast = useToast()
  // We are using `react-router` for this example,
  // but feel free to omit this or use the
  // router of your choice.
  // If we change page, reset the error state.

  // Check if there is a currently active session
  // when the provider is mounted for the first time.
  //
  // If there is an error, it means there is no session.
  //
  // Finally, just signal the component that the initial load
  // is over.
  useEffect(() => {
    const session = Tokens.GetAccessToken()
    if (session) setIsLoggedIn(true)
    setLoading(false)
  }, [])

  useEffect(() => {
    !loading &&
      !user &&
      isLoggedIn &&
      Me.Get()
        .then((me) => setUser(me))
        .catch((err) => setIsLoggedIn(false))
  }, [user, loading, isLoggedIn])
  // Flags the component loading state and posts the login
  // data to the server.
  //
  // An error means that the email/password combination is
  // not valid.
  //
  // Finally, just signal the component that loading the
  // loading state is over.
  const login = useCallback(async (username: string, password: string) => {
    setLoading(true)
    await Auth.Login(username, password, appConstants.clientID, ['FullAccess'])
      .then(({ access_token, refresh_token }) => {
        Tokens.SetAccessToken(access_token)
        refresh_token && Tokens.SetRefreshToken(refresh_token)
      })
      .then(() => Me.Get().then((me) => setUser(me)))
      .then(() => {
        setIsLoggedIn(true)
      })
      .catch((err) => {
        throw err
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  // Call the logout endpoint and then remove the user
  // from the state.
  const logout = () => {
    setLoading(true)
    Tokens.RemoveAccessToken()
    setUser(undefined)
    setIsLoggedIn(false)
    setLoading(false)
  }

  const updateUser = useCallback(
    (user?: MeUser) => {
      if (user) {
        setLoading(true)
        Me.Save(user)
          .then((me) => {
            toast({
              render: () => (
                <Toast>
                  <Text>All changes saved</Text>
                </Toast>
              ),
            })
            setUser(me)
          })
          .catch((err) => {
            throw err
          })
          .finally(() => {
            setLoading(false)
          })
      }
    },
    [setLoading, toast]
  )

  const updatePassword = useCallback(
    (password: string) => {
      setLoading(true)
      Me.ResetPasswordByToken({ NewPassword: password })
        .then(() => {
          toast({
            render: () => (
              <Toast>
                <Text>Password changed successfully</Text>
              </Toast>
            ),
          })
        })
        .catch((err: any) => {
          throw err
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [setLoading, toast]
  )

  // Make the provider update only when it should.
  // We only want to force re-renders if the user,
  // loading or error states change.
  //
  // Whenever the `value` passed into a provider changes,
  // the whole tree under the provider re-renders, and
  // that can be very costly! Even in this case, where
  // you only get re-renders when logging in and out
  // we want to keep things very performant.
  const memoedValue = useMemo(
    () => ({
      isLoggedIn,
      user,
      loading,
      login,
      logout,
      updateUser,
      updatePassword,
    }),
    [isLoggedIn, user, loading, login, updateUser, updatePassword]
  )

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  )
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useAuth() {
  return useContext(AuthContext)
}
