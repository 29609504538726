import FormInput from '../../Shared/FormInput/FormInput'

const AccountDetails: React.FC = () => {
  return (
    <fieldset>
      <FormInput type="text" name="Username" id="Username" />
      <FormInput type="text" name="Email" id="Email" readonly={true} />
    </fieldset>
  )
}

export default AccountDetails
