import { useFormikContext } from 'formik'
import { Button, ButtonProps } from '@chakra-ui/react'

interface FormSubmitButtonProps extends ButtonProps {
  formid?: string
}
const FormSubmitButton = (props: FormSubmitButtonProps) => {
  const { isSubmitting, isValid } = useFormikContext()
  const { children, formid } = props

  return (
    <>
      <Button
        {...props}
        disabled={!isValid}
        isLoading={isSubmitting}
        form={formid}
        type="submit"
      >
        {children}
      </Button>
    </>
  )
}
export default FormSubmitButton
