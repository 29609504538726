import { Flex, Spacer, Button, Box } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import FormSubmitButton from '../FormSubmitButton/FormSubmitButton'

interface SaveBarProps {
  formid?: string
}

//TODO: still need to figure out spacing/positioning for use on profile page and details pages
const SaveBar: React.FC<SaveBarProps> = ({ formid }) => {
  const { resetForm } = useFormikContext()
  return (
    <Box
      p={3}
      position="absolute"
      width="calc(100vw - 240px + 2.5rem)" // full viewport width - drawer width + main element's padding
      bottom="0"
      right="0"
      borderTop={'1px'}
      borderColor={'blackAlpha.200'}
      zIndex={1}
      backgroundColor={'#FAFAFA'}
    >
      <Flex align="right">
        <Spacer />
        <Button variant="outline" mx="1" onClick={() => resetForm()}>
          Discard
        </Button>
        <FormSubmitButton mx="1" formid={formid}>
          Save Changes
        </FormSubmitButton>
      </Flex>
    </Box>
  )
}

export default SaveBar
