import { Box, FormControl, FormLabel, Heading, Switch } from '@chakra-ui/react'
import { Formik } from 'formik'
import { User } from 'ordercloud-javascript-sdk'
import { PropsWithChildren } from 'react'
import * as yup from 'yup'
import FormInput from '../FormInput/FormInput'

interface GroupFormProps {
  user?: any
  renderSaveComponent: any
  saveCallback: (user: User) => void
}
const UserForm: React.FC<PropsWithChildren<GroupFormProps>> = ({
  user,
  saveCallback,
  renderSaveComponent,
}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        ID: user?.ID ?? '',
        Username: user?.Username ?? '',
        FirstName: user?.FirstName ?? '',
        LastName: user?.LastName ?? '',
        Email: user?.Email ?? '',
        Phone: user?.Phone ?? '',
        Active: user?.Active ?? true,
      }}
      validationSchema={yup.object({
        ID: yup.string(),
        Username: yup.string().required(),
        FirstName: yup.string().required(),
        LastName: yup.string().required(),
        Email: yup.string().required().email(),
        Phone: yup
          .string()
          .matches(
            /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
            'Phone number is invalid'
          ),
        Active: yup.boolean().required(),
      })}
      onSubmit={saveCallback}
    >
      {({ handleSubmit, handleChange, values }: any) => (
        <>
          <form id="user" onSubmit={handleSubmit}>
            <Box
              borderRadius="3xl"
              marginBottom="6"
              padding="6"
              backgroundColor="panel-background"
            >
              <Heading as="h2" size="md" my="3">
                Personal Details
              </Heading>
              <FormInput type="text" name="First Name" id="FirstName" />
              <FormInput type="text" name="Last Name" id="LastName" />
              <FormInput type="text" name="Phone" id="Phone" />
            </Box>
            <Box
              borderRadius="3xl"
              marginBottom="6"
              padding="6"
              backgroundColor="panel-background"
            >
              <Heading as="h2" size="md" my="3">
                Account Details
              </Heading>
              <FormInput type="text" name="Email" id="Email" />
              <FormInput type="text" name="Username" id="Username" />
              <FormInput type="text" name="ID" id="ID" />
              <FormControl>
                <FormLabel>Active</FormLabel>
                <Switch
                  id="Active"
                  name="Active"
                  isChecked={values.Active}
                  onChange={handleChange}
                />
              </FormControl>
            </Box>
          </form>
          {renderSaveComponent}
        </>
      )}
    </Formik>
  )
}

export default UserForm
