import { Badge, Button, Stack, Td, Tr } from '@chakra-ui/react'
import { isNil } from 'lodash'
import { ChangeEvent, useCallback, useMemo } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { useOcBuyerUsers } from '../../../common/hooks'
import FilterMenu from '../../Shared/FilterMenu/FilterMenu'
import OcTable from '../../Shared/OcTable/OcTable'
import SearchInput from '../../Shared/SearchInput/SearchInput'

export const BuyerUsersList = () => {
  const { buyerID } = useParams()
  const {
    loading,
    items,
    onQueryParamChange,
    resetQueryParams,
    hasQueryParams,
    listOptions,
  } = useOcBuyerUsers(buyerID!)

  const statusFilterValue = useMemo(() => {
    if (isNil(listOptions.filters?.Active)) {
      return
    }
    return listOptions.filters?.Active ? 'active' : 'inactive'
  }, [listOptions.filters])

  const handleStatusFilterChange = useCallback(
    (value: string | string[] | null) => {
      onQueryParamChange('Active')({
        target: { value: isNil(value) ? null : value === 'active' },
      } as ChangeEvent<any>)
    },
    [onQueryParamChange]
  )

  return (
    <>
      <Stack
        direction="row"
        spacing={4}
        marginBottom={6}
        justify="space-between"
      >
        <Stack direction="row" spacing={4}>
          <SearchInput
            value={listOptions.search}
            onChange={onQueryParamChange('search')}
            debounce={800}
          />
          <FilterMenu
            label="Status"
            value={statusFilterValue}
            onChange={handleStatusFilterChange}
            options={[
              {
                label: 'Active',
                value: 'active',
              },
              {
                label: 'Inactive',
                value: 'inactive',
              },
            ]}
          />
        </Stack>
        <Button variant="solid" colorScheme="brand">
          New user
        </Button>
      </Stack>
      <OcTable
        variant="simple"
        headers={['Name', 'Email', 'Username', 'ID', 'Status']}
        loading={loading}
        emptyList={!items?.length}
        hasQueryParams={hasQueryParams}
        resetQueryParams={resetQueryParams}
        resourceName="buyer users"
      >
        {items?.map((u, i) => {
          return (
            <Tr
              key={i}
              as={NavLink}
              display="table-row"
              to={`/buyers/${buyerID}/users/${u.ID}`}
            >
              <Td>
                <b>{u.FirstName ? `${u.FirstName} ${u.LastName}` : ''}</b>
              </Td>
              <Td>{u.Email}</Td>
              <Td>{u.Username}</Td>
              <Td>{u.ID}</Td>
              <Td>
                <Badge colorScheme={u.Active ? 'green' : 'yellow'}>
                  {u.Active ? `ACTIVE` : `INACTIVE`}
                </Badge>
              </Td>
            </Tr>
          )
        })}
      </OcTable>
    </>
  )
}
