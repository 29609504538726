import { ApprovalRule, ApprovalRules } from 'ordercloud-javascript-sdk'
import { useMemo } from 'react'
import useOcListPage from './useOcListPage'

export const useOcApprovalRules = <XpType = any>(buyerID: string) => {
  const routeParams = useMemo(() => {
    return new Array(buyerID!)
  }, [buyerID])

  return useOcListPage<ApprovalRule<XpType>>(ApprovalRules.List, routeParams)
}

export default useOcApprovalRules
