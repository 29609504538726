import { SpendingAccount, SpendingAccounts } from 'ordercloud-javascript-sdk'
import { useMemo } from 'react'
import useOcListPage from './useOcListPage'

export const useOcSpendingAccounts = <XpType = any>(buyerID: string) => {
  const routeParams = useMemo(() => {
    return new Array(buyerID!)
  }, [buyerID])

  return useOcListPage<SpendingAccount<XpType>>(
    SpendingAccounts.List,
    routeParams
  )
}

export default useOcSpendingAccounts
