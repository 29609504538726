import { Flex, Box, DrawerFooter } from '@chakra-ui/react'
import ConfirmCancelModal from '../ConfirmCancel/ConfirmCancelModal'
import FormSubmitButton from '../FormSubmitButton/FormSubmitButton'

interface DrawerSaveBarProps {
  onClose: any
  formid?: string
}

const DrawerSaveBar: React.FC<DrawerSaveBarProps> = ({ formid, onClose }) => {
  return (
    <DrawerFooter>
      <Box p={3} position="fixed" bottom="0">
        <Flex align="right">
          <ConfirmCancelModal onConfirm={onClose} />
          <FormSubmitButton formid={formid}>Submit</FormSubmitButton>
        </Flex>
      </Box>
    </DrawerFooter>
  )
}

export default DrawerSaveBar
