export type Environment = 'local' | 'qa' | 'staging' | 'sandbox' | 'prod';

const appConstants = getAppConstants();
export default appConstants;

interface AppConstants {
  environment: Environment;
  clientID: string;
  recaptchaClientKey: string;
  ocApiUrl: string;
}
function getAppConstants(): AppConstants {
  const environment = getEnvironment();

  let result: AppConstants;
  switch (environment) {
    //There's no diff at _this_ point, but we can update these for hosting our starter example
    case 'prod':
      result = {
        environment: 'prod',
        clientID: 'AB68C6E1-725E-4F3C-999F-66CF5AEDB56D',
        recaptchaClientKey: '',
        ocApiUrl: 'https://sandboxapi.ordercloud.io',
      };
      break;
    default:
        result = {
            environment: 'local',
            clientID: 'AB68C6E1-725E-4F3C-999F-66CF5AEDB56D',
            recaptchaClientKey: '',
            ocApiUrl: 'https://sandboxapi.ordercloud.io',
        };
  }

  return result;
}

function getEnvironment(): Environment {
  switch (window.location.hostname) {
    case 'oc-portal.azurewebsites.net':
    case 'portal.ordercloud.io':
      return 'prod';
    default:
      return 'local';
  }
}
