import {
  Container,
  ResponsiveValue,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { faFaceMeh } from '@fortawesome/free-solid-svg-icons'
import { PropsWithChildren } from 'react'
import NoResults from '../NoResults/NoResults'

interface IOcTableProps {
  headers: string[]
  variant:
    | ResponsiveValue<(string & {}) | 'simple' | 'striped' | 'unstyled'>
    | undefined
  colGroups?: string[]
  resourceName: string
  loading: boolean
  emptyList: boolean
  hasQueryParams: boolean
  resetQueryParams: () => void
}

const OcTable: React.FC<PropsWithChildren<IOcTableProps>> = ({
  headers,
  variant,
  colGroups,
  resourceName,
  loading,
  emptyList,
  hasQueryParams,
  resetQueryParams,
  children: columnValues,
}) => {
  return (
    <TableContainer style={{ marginBottom: 25 }}>
      <Table variant={variant}>
        {colGroups && (
          <colgroup>
            {colGroups?.map((g, idx) => (
              <col width={g} key={idx} />
            ))}
          </colgroup>
        )}
        <Thead>
          <Tr>
            {headers?.map((h, idx) => (
              <Th key={idx}>{h}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {loading ? (
            <Tr>
              <Td colSpan={5}>
                <Container centerContent justifyContent="center" height="md">
                  <Spinner color="brand.500" />
                </Container>
              </Td>
            </Tr>
          ) : !emptyList ? (
            <>{columnValues}</>
          ) : (
            <Tr>
              <Td colSpan={5}>
                {hasQueryParams ? (
                  <NoResults
                    title={`No ${resourceName?.toLowerCase()} found`}
                    onClear={resetQueryParams}
                  />
                ) : (
                  <NoResults
                    title={`No ${resourceName?.toLowerCase()} created`}
                    icon={faFaceMeh}
                  />
                )}
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default OcTable
