import { RouteObject, useRoutes } from 'react-router-dom'
import Buyers from '../../components/Buyers'
import {
  BuyerAddressDetails,
  BuyerAddressesList,
} from '../../components/Buyers/Addresses'
import {
  BuyerApprovalRuleDetails,
  BuyerApprovalRulesList,
} from '../../components/Buyers/ApprovalRules'
import BuyerContainer from '../../components/Buyers/BuyerContainer'
import BuyerDetails from '../../components/Buyers/BuyerDetails'
import BuyersList from '../../components/Buyers/BuyersList'
import {
  BuyerGroupDetails,
  BuyerGroupsList,
} from '../../components/Buyers/Groups'
import {
  BuyerCostCenterDetails,
  BuyerPaymentMethodsList,
  BuyerSpendingAccountDetails,
} from '../../components/Buyers/PaymentMethods'
import {
  AllBuyerUsersList,
  BuyerUserDetails,
  BuyerUsersList,
} from '../../components/Buyers/Users'
import Dashboard from '../../components/Dashboard/index'
import Login from '../../components/Login/Login'
import MyAccount from '../../components/Profile'
import NoMatch from '../../components/Shared/NoMatch/NoMatch'
import RequireAuth from '../../context/RequireAuth'

const AppRoutes: RouteObject[] = [
  { path: '*', element: <NoMatch /> },
  {
    path: '/',
    element: (
      <RequireAuth>
        <Dashboard />
      </RequireAuth>
    ),
  },
  { path: '/login', element: <Login /> },
  { path: '/profile', element: <MyAccount /> },
  {
    path: `/buyer-users`,
    element: (
      <RequireAuth>
        <Buyers />
      </RequireAuth>
    ),
    children: [{ path: `/buyer-users`, element: <AllBuyerUsersList /> }],
  },
  {
    path: `/buyers`,
    element: (
      <RequireAuth>
        <Buyers />
      </RequireAuth>
    ),
    children: [
      { path: '/buyers', element: <BuyersList /> },
      {
        path: '/buyers/:buyerID/users/:userID',
        element: <BuyerUserDetails />,
      },
      {
        path: '/buyers/:buyerID',
        element: <BuyerContainer />,
        children: [
          { path: '/buyers/:buyerID', element: <BuyerDetails /> },
          { path: '/buyers/:buyerID/users', element: <BuyerUsersList /> },
          { path: '/buyers/:buyerID/groups', element: <BuyerGroupsList /> },
          {
            path: '/buyers/:buyerID/groups/:groupID',
            element: <BuyerGroupDetails />,
          },
          {
            path: '/buyers/:buyerID/addresses',
            element: <BuyerAddressesList />,
          },
          {
            path: '/buyers/:buyerID/addresses/:addressID',
            element: <BuyerAddressDetails />,
          },
          {
            path: '/buyers/:buyerID/payment-methods/:method',
            element: <BuyerPaymentMethodsList />,
          },
          {
            path: '/buyers/:buyerID/payment-methods/spending-accounts/:spendingAccountID',
            element: <BuyerSpendingAccountDetails />,
          },
          {
            path: '/buyers/:buyerID/payment-methods/cost-centers/:costCenterID',
            element: <BuyerCostCenterDetails />,
          },
          {
            path: '/buyers/:buyerID/approval-rules',
            element: <BuyerApprovalRulesList />,
          },
          {
            path: '/buyers/:buyerID/approval-rules/:approvalRuleID',
            element: <BuyerApprovalRuleDetails />,
          },
        ],
      },
    ],
  },
]

const Routing: React.FC = () => {
  const routesToRender = useRoutes(AppRoutes)
  return <>{routesToRender}</>
}

export default Routing
