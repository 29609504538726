import { Box } from "@chakra-ui/react"
import useAuth from "../../context/AuthContext"

function Dashboard() {
    const {user} = useAuth()
  return (
    <Box style={{padding: 40}}>
    <div>Dashboard</div>
    {user && <pre>{JSON.stringify(user, null, 4)}</pre>}
    </Box>
    
  )
}

export default Dashboard
