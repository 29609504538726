import {
  Badge,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  InputGroup,
  InputLeftElement,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Td,
  Text,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { faChevronRight, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isNil } from 'lodash'
import { Buyer, Buyers, OrderCloudError } from 'ordercloud-javascript-sdk'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import useOcBuyers from '../../common/hooks/useOcBuyers'
import ConfirmDeleteModal from '../Shared/ConfirmDelete/ConfirmDeleteModal'
import CreateDrawer from '../Shared/CreateDrawer/CreateDrawer'
import FilterMenu from '../Shared/FilterMenu/FilterMenu'
import OcTable from '../Shared/OcTable/OcTable'
import SearchInput from '../Shared/SearchInput/SearchInput'
import Toast from '../Shared/Toast/Toast'
import BuyerCreateForm from './BuyerForm'
import DrawerSaveBar from '../Shared/DrawerSaveBar/DrawerSaveBar'

const BuyersList = () => {
  const [error, setError] = useState<OrderCloudError>()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    loading,
    items,
    onQueryParamChange,
    resetQueryParams,
    refreshList,
    hasQueryParams,
    listOptions,
  } = useOcBuyers()
  error && console.log(error)

  const statusFilterValue = useMemo(() => {
    if (isNil(listOptions.filters?.Active)) {
      return
    }
    return listOptions.filters?.Active ? 'active' : 'inactive'
  }, [listOptions.filters])

  const handleStatusFilterChange = useCallback(
    (value: string | string[] | null) => {
      onQueryParamChange('Active')({
        target: { value: isNil(value) ? null : value === 'active' },
      } as ChangeEvent<any>)
    },
    [onQueryParamChange]
  )

  const createBuyer = (buyer: Buyer) => {
    Buyers.Create(buyer)
      .then((res) => {
        onClose()
        refreshList()
        toast({
          render: () => (
            <Toast>
              <Text>New buyer created successfully</Text>
              <br />
              <Link as={NavLink} to={`/buyers/${res.ID}`} fontWeight="bold">
                View buyer
              </Link>
            </Toast>
          ),
        })
      })
      .catch((err: OrderCloudError) => setError(err))
  }

  const deleteBuyer = (buyer: any) => {
    Buyers.Delete(buyer.ID)
      .then(() => {
        refreshList()
        toast({
          render: () => (
            <Toast>
              <Text>{buyer.Name} has been deleted.</Text>
            </Toast>
          ),
        })
      })
      .catch((err: OrderCloudError) => setError(err))
  }

  return (
    <Box padding={10} paddingTop={4}>
      <Breadcrumb
        marginBottom={8}
        spacing={4}
        separator={
          <FontAwesomeIcon size="xs" color="gray" icon={faChevronRight} />
        }
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={NavLink} to="/">
            Home
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink as={NavLink} to="/buyers">
            Buyers
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justifyContent="space-between">
        <Heading marginBottom="6">Buyers</Heading>
        <CreateDrawer
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
          resourceType="Buyer"
        >
          <BuyerCreateForm
            saveCallback={createBuyer}
            renderSaveComponent={
              <DrawerSaveBar onClose={onClose} formid="buyer" />
            }
          />
        </CreateDrawer>
      </Flex>
      <Stack
        direction="row"
        spacing={4}
        marginBottom={6}
        justify="space-between"
      >
        <Stack direction="row" spacing={4}>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              color="gray.300"
              fontSize="1.2em"
              children={<FontAwesomeIcon icon={faSearch} />}
            />
            <SearchInput
              value={listOptions.search}
              onChange={onQueryParamChange('search')}
              debounce={800}
            />
          </InputGroup>
          <FilterMenu
            label="Status"
            value={statusFilterValue}
            onChange={handleStatusFilterChange}
            options={[
              {
                label: 'Active',
                value: 'active',
              },
              {
                label: 'Inactive',
                value: 'inactive',
              },
            ]}
          />
        </Stack>
      </Stack>
      <OcTable
        variant="simple"
        headers={['Name', 'ID', 'Status', '']}
        loading={loading}
        emptyList={!items?.length}
        hasQueryParams={hasQueryParams}
        resetQueryParams={resetQueryParams}
        resourceName="buyers"
      >
        {items?.map((b, i) => {
          return (
            <Tr key={i} display="table-row">
              <Td>
                <Link as={NavLink} to={`/buyers/${b.ID}`}>
                  {' '}
                  {b.Name ?? '-'}
                </Link>
              </Td>
              <Td>{b.ID}</Td>
              <Td>
                <Badge colorScheme={b.Active ? 'green' : 'yellow'}>
                  {b.Active ? `ACTIVE` : `INACTIVE`}
                </Badge>
              </Td>
              <Td zIndex="3">
                <Menu>
                  <MenuButton>...</MenuButton>
                  <MenuList>
                    <MenuItem as={NavLink} to={`/buyers/${b.ID}`}>
                      Edit
                    </MenuItem>
                    <ConfirmDeleteModal
                      resourceType="buyer"
                      resourceName={b.Name}
                      onConfirm={() => deleteBuyer(b)}
                    >
                      <MenuItem>Delete buyer</MenuItem>
                    </ConfirmDeleteModal>
                  </MenuList>
                </Menu>
              </Td>
            </Tr>
          )
        })}
      </OcTable>
    </Box>
  )
}

export default BuyersList
