import { Button, Stack, Td, Tr } from '@chakra-ui/react'
import { Link, useParams } from 'react-router-dom'
import { useOcBuyerAddresses } from '../../../common/hooks'
import OcTable from '../../Shared/OcTable/OcTable'
import SearchInput from '../../Shared/SearchInput/SearchInput'

export const BuyerAddressesList = () => {
  const { buyerID } = useParams()
  const {
    loading,
    items,
    onQueryParamChange,
    resetQueryParams,
    listOptions,
    hasQueryParams,
  } = useOcBuyerAddresses(buyerID!)

  return (
    <>
      <Stack
        direction="row"
        spacing={4}
        marginBottom={6}
        justify="space-between"
      >
        <Stack direction="row" spacing={4}>
          <SearchInput
            value={listOptions.search}
            onChange={onQueryParamChange('search')}
            debounce={1500}
          />
          {/* FILTERS GO HERE */}
        </Stack>
        <Button variant="solid" colorScheme="brand">
          New address
        </Button>
      </Stack>
      <OcTable
        variant="simple"
        headers={['Address name', 'Name', 'Address', 'Assigned to']}
        colGroups={['25%', '10%', '50%', '15%']}
        loading={loading}
        emptyList={!items?.length}
        hasQueryParams={hasQueryParams}
        resetQueryParams={resetQueryParams}
        resourceName="addresses"
      >
        {items?.map((a, i) => {
          return (
            <Tr key={i}>
              <Td>
                <Link to={`/buyers/${buyerID}/addresses/${a.ID}`}>
                  {a.AddressName ?? '-'}
                </Link>
              </Td>
              <Td>{a.FirstName ? `${a.FirstName} ${a.LastName}` : ''}</Td>
              <Td>{`${a.Street1} ${a.Street2 ? `, ${a.Street2}` : ``}, ${
                a.City
              }, ${a.State}, ${a.Zip}, ${a.Country}`}</Td>
              {<Td>dev incomplete</Td>}
              {/* {a?.assignments?.items?.length > 0 && <Td>{a?.assignments?.items?.length > 1 ? `${a?.assignments?.items?.[0]?.level === 'Company' ? 'This buyer' : a?.assignments?.items?.[0]?.entity?.name} + ${a?.assignments?.items?.length - 1} more` : a?.assignments?.items?.[0]?.entity?.name}</Td>} */}
            </Tr>
          )
        })}
      </OcTable>
    </>
  )
}
