import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'

interface ConfirmModalProps {
  onConfirm: () => void
}

const ConfirmCancelModal: React.FC<ConfirmModalProps> = ({ onConfirm }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button variant="outline" mr={3} onClick={onOpen}>
        Cancel
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you sure?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>If you exit now, you will lose progress. </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onConfirm} variant="link">
              Exit
            </Button>
            <Button rounded="full" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ConfirmCancelModal
