import { Box, Input, Skeleton, Text } from '@chakra-ui/react'
import { UserGroup, UserGroups } from 'ordercloud-javascript-sdk'
import { useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useOc } from '../../../common/hooks'

export const BuyerGroupDetails = () => {
  const { buyerID, groupID } = useParams()
  const { state } = useLocation()
  const routeParams = useMemo(() => {
    return [buyerID!, groupID!]
  }, [buyerID, groupID])
  const { loading, data } = useOc<UserGroup>(
    UserGroups.Get,
    routeParams,
    state as UserGroup
  )

  return (
    <Skeleton isLoaded={!loading}>
      <Text fontSize={30} fontWeight="bold">
        {data?.Name}
      </Text>
      <Box
        style={{
          margin: 20,
          borderRadius: 15,
          padding: '20px',
          backgroundColor: '#FAFAFA',
        }}
      >
        <p style={{ fontSize: 18, fontWeight: 'bold' }}>Group details</p>
        <Text mb="8px">Name</Text>
        <Input readOnly value={data?.Name} />
        <Text mb="8px">ID</Text>
        <Input readOnly value={data?.ID} />
        <Text mb="8px">Description</Text>
        <Input readOnly value={data?.Description} />
      </Box>
    </Skeleton>
  )
}
