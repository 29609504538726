import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Configuration } from 'ordercloud-javascript-sdk'
import appConstants from './config/app.constants'
import { BrowserRouter } from 'react-router-dom'

Configuration.Set({
  baseApiUrl: appConstants.ocApiUrl,
  apiVersion: 'v1',
})

// TODO: Figure out way to move BrowswerRouter back into App.tsx- without breaking toasts

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
