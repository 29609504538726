import { List, ListItem, Text, Icon } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

interface PasswordStrengthProps {
  errors: any
}
const PasswordStrength: React.FC<PasswordStrengthProps> = ({ errors }) => {
  const errorArr: { fullMsg: string; shortMsg: string }[] = [
    {
      shortMsg: '10 or more characters',
      fullMsg: 'Password must be 10 or more characters',
    },
    {
      shortMsg: '1 lowercase letter',
      fullMsg: 'Password must contain at least 1 lowercase character',
    },
    {
      shortMsg: '1 uppercase letter',
      fullMsg: 'Password must contain at least 1 uppercase character',
    },
    {
      shortMsg: '1 number',
      fullMsg: 'Password must contain at least 1 number',
    },
  ]

  return (
    <List aria-label="Password Criteria">
      {errorArr.map((err, idx) => {
        return (
          <ListItem my="2" key={idx}>
            <Text color="gray">
              <Icon
                as={FontAwesomeIcon}
                size="sm"
                marginRight={2}
                icon={faCheckCircle}
                color={
                  !errors?.includes(err.fullMsg) ? 'green.400' : 'gray.400'
                }
              />
              {err.shortMsg}
            </Text>
          </ListItem>
        )
      })}
    </List>
  )
}

export default PasswordStrength
